import { themeGet } from '@styled-system/theme-get';
import { useCombobox } from 'downshift';
import { motion } from 'framer-motion';
import { get, isFunction } from 'lodash';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import { variant as styledVariant } from 'styled-system';

import { Box } from '@/components/box';
import { Icon } from '@/components/icon';
import { Error } from '@/components/inputs/error';
import { Label } from '@/components/inputs/label';
import { LoadingIndicator } from '@/components/ui/loading/indicator';
import { useDebounce } from '@/hooks/use-debounce';
import { useLocationAutocomplete } from '@/hooks/use-location-autocomplete';
import { usePlaceCache } from '@/hooks/use-place-cache';
import { AIRPORT, AUTOCOMPLETE_ALGOLIA, INPUT_HEIGHT, SEAPORT } from '@/lib/constants';
import { isSearchingLocationAutocompleteState } from '@/state/search';
import { space } from '@/theme/constants';
import { AutocompletePlaceTypes, LocationInterface } from '@/types';
import { NodeType, PlaceInterface, PlaceType } from '@/types/api-types';
import { LocationAutocompleteIdInterface } from '@/types/context-types';
import { FieldErrors } from '@/types/form-types';
import { logEvent } from '@/utils/logger';
import { media } from '@/utils/media';
import { trackUser } from '@/utils/tracking';

interface Props extends FieldErrors {
  name: LocationAutocompleteIdInterface;
  label?: string;
  showLabel?: boolean;
  placeholder: string;
  selectedLocation?: LocationInterface | null;
  defaultValue?: string;
  icon?: string;
  onSelect?: (place: PlaceInterface | undefined, name: string) => void;
  variant?: 'primary' | 'secondary';
  colorVariant?: 'light' | 'dark';
  placeTypes?: AutocompletePlaceTypes;
  isDisabled?: boolean;
  className?: string;
}

const LocationAutocomplete = ({
  name,
  label,
  showLabel = true,
  defaultValue = '',
  selectedLocation = null,
  placeholder,
  onSelect,
  icon,
  placeTypes,
  variant = 'primary',
  colorVariant = 'light',
  isDisabled = false,
  className,
  errors,
}: Props) => {
  const { getPlaceWithLocation } = usePlaceCache();
  const shouldAutoSelect = useRef(false);
  const selectedIndex = useRef(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState<string | undefined>(defaultValue);
  const debouncedQuery = useDebounce(query, 150);
  const [isSearchingLocationAutocomplete, setIsSearchingLocationAutocomplete] = useRecoilState(
    isSearchingLocationAutocompleteState,
  );
  const { suggestions } = useLocationAutocomplete({
    name,
    query: debouncedQuery,
    placeTypes,
  });

  const fieldError = get(errors, name);
  const errorMessage = fieldError?.message;

  /**
   * On Default Value change
   * Update the input value and trigger a geocode place request if a value exists
   */
  useEffect(() => {
    if (defaultValue) {
      // Update if values are different
      if (defaultValue !== inputValue) {
        setInputValue(defaultValue);
        shouldAutoSelect.current = true;
      }
    } else if (!defaultValue && inputValue) {
      // Only reset if there already is a value
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  /**
   * Watch for selectedLocation changes (LocationInterface)
   * This will use the object as-is and not do a "lookup"
   */
  useEffect(() => {
    if (selectedLocation) {
      // Update if values are different
      if (selectedLocation.name !== inputValue) {
        selectItem(selectedLocation);
      }
    } else if (!selectedLocation && inputValue) {
      // Only reset if there already is a value
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  /**
   * watch for suggestion changes
   */
  useEffect(() => {
    if (suggestions.length && shouldAutoSelect.current) {
      // autoselect the first item
      selectItem(suggestions[0]);
      shouldAutoSelect.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions]);

  /**
   * Fetch Place Details
   * Fetches place details from the Google API
   */
  const fetchPlaceDetails = async (item: PlaceInterface): Promise<PlaceInterface | undefined> => {
    if (!item || !item.placeId) return undefined;
    if (item.location) return item;

    setIsSearchingLocationAutocomplete(name);

    try {
      // For some reason, Google sometimes returns a different name
      // than the one originally displayed in the search field
      const placeWithOriginalName = await getPlaceWithLocation({ place: item });

      setIsSearchingLocationAutocomplete(null);
      return placeWithOriginalName;
    } catch (error) {
      setIsSearchingLocationAutocomplete(null);
      logEvent.error('Autocomplete Fetch Places Error', { error });
      return undefined;
    }
  };

  const handleSetSelection = async (selection: PlaceInterface, userInitiated = false) => {
    if (selection) {
      const selectedPlace = await fetchPlaceDetails(selection);
      if (isFunction(onSelect) && selectedPlace) onSelect(selectedPlace, name);

      // Analytics Event sent only if user initiated
      if (userInitiated) {
        trackUser.event('Select Autocomplete Location', { place: selectedPlace });
      }
    }
  };

  const setSelectedIndex = () => {
    setHighlightedIndex(-1);
    // set Selected Index
    if (suggestions.length && selectedItem) {
      const suggestionIndex = suggestions.findIndex((s) => s.name === selectedItem.name);
      selectedIndex.current = suggestionIndex < 0 ? 0 : suggestionIndex;
    }
  };

  const clearSelectedIndex = () => {
    selectedIndex.current = -1;
  };

  const clearSelection = (): void => {
    reset();
    focusInput();

    if (isFunction(onSelect)) {
      onSelect(undefined, name);
    }
  };

  const focusInput = (): void => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  };

  const onInputFocus = (e: { target: HTMLInputElement }): void => {
    e.target.select();
    openMenu();
    setSelectedIndex();
  };

  /**
   * UseCombo Hook
   * Hook props, gets and setters from Downshift
   */
  const {
    isOpen,
    getLabelProps,
    getInputProps,
    getComboboxProps,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    openMenu,
    closeMenu,
    selectedItem,
    setHighlightedIndex,
    setInputValue,
    inputValue,
    selectItem,
    reset,
  } = useCombobox({
    id: `autocomplete-${name}`,
    items: suggestions,
    onInputValueChange({ inputValue: newInputValue, type }) {
      setQuery(newInputValue);
      if (!newInputValue) return;
      if (
        type === useCombobox.stateChangeTypes.InputChange ||
        type === useCombobox.stateChangeTypes.FunctionSetInputValue
      ) {
        // Only fetch from server if the user typed a character or it was programatically set
        setHighlightedIndex(0);
      }
      clearSelectedIndex();
    },
    async onSelectedItemChange({ selectedItem: selection, isOpen: menuIsOpen }) {
      if (selection) {
        handleSetSelection(selection, menuIsOpen);
      }
      closeMenu();
    },
    itemToString: (item) => item?.name || '',
    onStateChange: ({ type }) => {
      switch (type) {
        // On Press Escape, reset value to what it was
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          if (selectedItem) {
            setInputValue(selectedItem.name);
          } else {
            reset();
          }
          break;
        default:
          break;
      }
    },
  });

  return (
    <Wrapper
      position="relative"
      variant={variant}
      isDisabled={isDisabled}
      className={className}
      hasError={fieldError}
      {...getComboboxProps()}
    >
      {showLabel && (
        <Label variant={colorVariant} {...getLabelProps()}>
          {label}
        </Label>
      )}
      <InputWrapper>
        {icon && (
          <InputIcon>
            <Icon name={icon} size="base" color="grey.800" className="m-0" />
          </InputIcon>
        )}
        <StyledInput
          {...getInputProps({
            placeholder,
            ref: inputRef,
            onFocus: onInputFocus,
          })}
          hasIcon={!!icon}
          disabled={isDisabled}
        />
        <InputExtra>
          {isSearchingLocationAutocomplete === name && <LoadingIndicator />}
          {isSearchingLocationAutocomplete !== name && selectedItem && (
            <ClearButton onClick={() => clearSelection()}>
              <Icon name="close" size="base" color="grey.400" className="m-0" />
            </ClearButton>
          )}
        </InputExtra>
      </InputWrapper>
      <Box {...getMenuProps()}>
        {isOpen && query && suggestions.length > 0 && (
          <DropDown
            initial="hidden"
            exit="hidden"
            animate={isOpen ? 'open' : 'hidden'}
            variants={{
              open: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0.8 },
            }}
          >
            {suggestions.map((item, index) => {
              if (item.from === AUTOCOMPLETE_ALGOLIA) {
                // Algolia results
                return (
                  <Fragment key={item.placeId}>
                    <DropDownItem
                      {...getItemProps({
                        item,
                        index,
                      })}
                      isHighlighted={index === highlightedIndex}
                      isSelected={index === selectedIndex.current}
                    >
                      <span>
                        {item.shortName} <strong>({item?.identifier?.value})</strong>
                      </span>
                      <Badge color={item.type}>{item.type}</Badge>
                    </DropDownItem>
                  </Fragment>
                );
              }
              // Google results
              return (
                <Fragment key={item.placeId}>
                  <DropDownItem
                    {...getItemProps({ item, index })}
                    isHighlighted={index === highlightedIndex}
                    isSelected={index === selectedIndex.current}
                  >
                    {item.name}
                    <Badge>{item.type}</Badge>
                  </DropDownItem>
                </Fragment>
              );
            })}
          </DropDown>
        )}
      </Box>
      {fieldError && <Error className="error mb-2">{errorMessage}</Error>}
    </Wrapper>
  );
};

const variants = styledVariant({
  scale: 'locationAutocomplete',
  variants: {
    primary: {
      input: {
        backgroundColor: 'white',
        height: INPUT_HEIGHT,
        padding: `${space[3]}  ${space.base}`,
        borderRadius: 'md',

        '&:hover': {
          borderColor: 'grey.400',
        },
        '&:focus': {
          borderColor: 'lightBlue.500',
        },
      },
      label: {
        marginBottom: 'xs',
      },
    },
    secondary: {
      input: {
        borderRadius: 'default',
        backgroundColor: 'grey.100',
        fontSize: 'sm',
        height: '34px', // Should be 32px (space.lg) but needs to account for the 2px border
        padding: `${space[2]} ${space[3]}`,

        '&:hover': {
          borderColor: 'grey.400',
        },
        '&:focus': {
          borderColor: 'grey.500',
        },
      },
      label: {
        marginBottom: 'xs',
      },
    },
  },
});

const Wrapper = styled(Box)`
  ${variants};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;

      input {
        pointer-events: none;
      }
    `}

  /* @NOTE: On all other form elements, this is handled in the theme file */
  ${({ hasError }) =>
    hasError &&
    css`
      input {
        border-color: ${themeGet('colors.forms.states.error')};
        box-shadow: 0 0 0 1px ${themeGet('colors.forms.states.error')};
        color: ${themeGet('colors.forms.states.error')};

        &:hover:not(:focus) {
          border-color: ${themeGet('colors.forms.states.error')};
        }

        &::placeholder {
          color: ${themeGet('colors.forms.states.error')};
        }
      }

      .error {
        color: ${themeGet('colors.forms.states.error')};
      }
    `}
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;

  ${media.md} {
    max-width: 480px;
  }
`;

interface StyledInputProps {
  readonly hasIcon: boolean;
}

const StyledInput = styled.input<StyledInputProps>`
  width: 100%;
  font-size: ${themeGet('fontSizes.base')};
  border: solid 1px ${themeGet('colors.grey.300')};
  padding-right: ${themeGet('space.8')};
  transition: all linear 150ms;
  text-overflow: ellipsis;
  box-shadow: ${themeGet('shadows.softer')};
  position: relative;

  &:hover,
  &:focus {
    z-index: ${themeGet('zIndices.2')};
  }

  &::-webkit-input-placeholder {
    color: ${themeGet('colors.grey.400')};
  }

  ${media.md} {
    font-size: ${themeGet('fontSizes.baseSm')};
  }

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-left: ${themeGet('space.8')} !important;
    `}
`;

const DropDown = styled(motion.div)`
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: ${themeGet('zIndices.10')};
  margin-top: ${themeGet('space.xs')};
  border-radius: ${themeGet('radii.md')};
  border: solid 1px ${themeGet('colors.grey.300')};
  background: white;
  padding: ${themeGet('space.sm')};
  max-width: 500px;
  min-width: 350px;
  transform-origin: top left;
  box-shadow: ${themeGet('shadows.softer')};
`;

interface ItemProps {
  isHighlighted: boolean;
  isSelected: boolean;
}

// @TODO: Move this to a shared file and use it in both the multi-select and the location picker
export const DropDownItem = styled(Box)<ItemProps>`
  font-size: ${themeGet('fontSizes.baseSm')};
  padding: ${themeGet('space.xs')} ${themeGet('space.sm')};
  min-height: ${themeGet('space.lg')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${themeGet('radii.default')};
  background-color: white;
  cursor: pointer;

  strong {
    font-weight: ${themeGet('fontWeights.medium')};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: ${themeGet('fontWeights.semiBold')};
      background-color: #eff5f6;
    `}

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: ${themeGet('colors.grey.100')};
    `}
`;

interface BadgeProps {
  color?: NodeType | PlaceType;
}

const Badge = styled.span<BadgeProps>`
  font-size: ${themeGet('fontSizes.xxs')};
  background: ${themeGet('colors.grey.200')};
  padding: 1px ${themeGet('space.xs')};
  border-radius: ${themeGet('radii.default')};
  margin-left: ${themeGet('space.base')};
  font-weight: ${themeGet('fontWeights.normal')};
  text-transform: uppercase;

  ${({ color }) =>
    color === AIRPORT &&
    css`
      background: ${themeGet('colors.transport.plane')};
      color: white;
    `}

  ${({ color }) =>
    color === SEAPORT &&
    css`
      background: ${themeGet('colors.transport.ship')};
      color: white;
    `}
`;

const InputExtra = styled.div`
  width: ${themeGet('space.8')};
  height: ${themeGet('space.8')};
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  transform: translateY(-50%);
  display: grid;
  place-items: center;
  z-index: ${themeGet('zIndices.3')};
`;

const ClearButton = styled.div`
  width: ${themeGet('space.md')};
  height: ${INPUT_HEIGHT};
  display: grid;
  place-items: center;
  cursor: pointer;

  &:hover svg {
    fill: ${themeGet('colors.grey.500')};
  }
`;

const InputIcon = styled.div`
  position: absolute;
  top: 50%;
  left: ${themeGet('space.base')};
  transform: translateY(-50%);
  z-index: ${themeGet('zIndices.3')};
`;

export { LocationAutocomplete };
